<template>
  <div class="reserved-area-page creator-dashboard">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <div class="reserved-area-form-group-wrapper">
            <p class="section-title reserved-area-form-title">ATIVIDADE</p>
            <div class="reserved-area-form-group">
              <div class="row">
                <!--<div class="reserved-area-form-column col">
                  <div class="form-field">
                    <FormulateInput type="select" name="filter-by" label="Filtra Por" :options="{first: 'Treinos'}" />
                  </div>
                </div>-->
                <div class="reserved-area-form-column col">
                  <div class="form-field">
                    <FormulateInput type="select" name="year" label="Ano" :options="{first: '2021'}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-chart-wrapper">
              <div class="box-chart">
                <chart/>
              </div>
            </div>
            <div class="reserved-area-form-group">
              <div class="row">
                <div class="reserved-area-form-column col-12">
                  <div class="form-field">
                    <FormulateInput type="select" name="filter-by" label="Filtra Por" :options="{first: 'Ultimo Mês'}" />
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-creator-totals">
              <div class="reserved-area-creator-total-item">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <p class="reserved-area-creator-total-item-title">Total</p>
                    <p class="reserved-area-creator-total-item-subtitle">Treinos Vendidos</p>
                  </div>
                  <p class="reserved-area-creator-total-item-value">{{ dashboard.totalWorkouts }}</p>
                </div>
              </div>
              <!--<div class="reserved-area-creator-total-item">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <p class="reserved-area-creator-total-item-title">Total</p>
                    <p class="reserved-area-creator-total-item-subtitle">Treinos PT</p>
                  </div>
                  <p class="reserved-area-creator-total-item-value">50</p>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import chart from "@/components/creator-chart.vue";
import axios from "axios";

export default {
  name: "reserved-area-creator-dashboard",
  data() {
    return {
        errors: [],
        dashboard: [],
    }
  },
  components: {
    reservedAreaMenu,
    chart
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'authors/dashboard',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.dashboard = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        });
  }
}
</script>